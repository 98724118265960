import { ThemeOptions } from '@mui/material';


export const themeOptions: ThemeOptions = {
    palette: {
        primary: {
            main: '#004d40',
            light: '#39796b',
            dark: '#00251a',
        },
        secondary: {
            main: '#ffb300',
            light: '#ffe54c',
            dark: '#c68400',
        },
    },
    components: {
        MuiList: {
            styleOverrides: {
                dense: true,
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                dense: true,
            }
        },
        // MuiTable: {
        //     defaultProps: {
        //         size: 'small',
        //     }
        // },
        MuiAppBar: {
            defaultProps: {
                color: 'default',
            }
        },
    },
};
