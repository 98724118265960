import React, { ComponentProps, FC, useContext } from 'react';
import TabPanel from '../TabPanel/TabPanel';
import DescriptionsGrid from '../../../common/DescriptionsGrid/DescriptionsGrid';
import DataField from '../../../common/DataField/DataField';
import { DogContext } from '../../Details';

type  OverviewTabPanelProps = ComponentProps<typeof TabPanel>

const OverviewTabPanel: FC<OverviewTabPanelProps> = (props) => {
    const {
        name,
        owner,
        location,
        color,
        registryId,
        notes,
        breedId,
        exteriorTypeId,
        birth_date = ''
    } = useContext(DogContext);

    return (
        <TabPanel {...props}>
            <DescriptionsGrid>
                <DataField label={'Кличка'} value={name} />
                <DataField label={'Владелец'} value={owner?.name} />
                <DataField label={'Адрес'} value={location} />
                <DataField label={'Окрас'} value={color} />
                <DataField label={'Экстерьер'} value={exteriorTypeId} />
                <DataField label={'Порода'} value={breedId} />
                <DataField label={'Дата рождения'} value={new Date(birth_date).toLocaleDateString()} />
                <DataField label={'Регистрационный номер'} value={registryId} />
                <DataField label={'Дополнительно'} value={notes} />
            </DescriptionsGrid>
        </TabPanel>
    );
};

export default OverviewTabPanel;
