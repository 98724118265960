import React, { SyntheticEvent, useState } from 'react';
import { Paper, Tab, Tabs } from '@mui/material';
import TabPanel from './TabPanel/TabPanel';
import OverviewTabPanel from './OverviewTabPanel/OverviewTabPanel';
import PedigreeTabPanel from './PedigreeTabPanel/PedigreeTabPanel';

const DetailsPanel = () => {

    const [currentTab, setCurrentTab] = useState(0);

    const handleChange = (event: SyntheticEvent, newTab: number) => {
        setCurrentTab(newTab);
    };

    return (
        <Paper>
            <Tabs value={currentTab} onChange={handleChange}>
                <Tab label="Общее" />
                <Tab label="Родословная"  />
                <Tab label="Награды"/>
                <Tab label="Дополнительно"/>
            </Tabs>

            <OverviewTabPanel value={currentTab} index={0} />

            <PedigreeTabPanel value={currentTab} index={1}>
                Item Two
            </PedigreeTabPanel>
            <TabPanel value={currentTab} index={2}>
                Item Three
            </TabPanel>
            <TabPanel value={currentTab} index={3}>
                Item Four
            </TabPanel>
        </Paper>
    );
};

export default DetailsPanel;
