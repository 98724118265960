import React, { useContext } from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { DogContext } from '../Details';

const OverviewCard = () => {
    const {name, notes, owner, registryId} = useContext(DogContext);

    return (
        <Card>
            <CardMedia
                component="img"
                height="250"
                image="https://placebear.com/g/400/200"
                alt="green iguana"
            />
            <CardContent>
                <Typography gutterBottom variant="h4" component="div">
                    {name}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                    {owner?.name}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                    {registryId}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                    {notes}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default OverviewCard;
