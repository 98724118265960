import React, { useState } from 'react';
import DogsTable from './DogsTable/DogsTable';
import DogsFilterBar, { IFilterBarValue } from './DogsFilterBar/DogsFilterBar';
import { Container, ownerDocument, Paper } from '@mui/material';
import { Dog } from '../../types/Dog';
import { ChangeEvent } from 'react';
import { stringify } from 'querystring';
import { useEffect } from 'react';
import { searchDogsRegistry } from '../../service/api';


const Search = () => {

    const [dogsTableData, setDogsTableData] = useState<Dog[]>([]);

    const handleSearch = async (filterBarValue: Partial<IFilterBarValue>) => {
        const foundResults = await searchDogsRegistry(filterBarValue);
        setDogsTableData(foundResults);
    }

    return (
        <Container sx={{mt: "32px"}}>
            <DogsFilterBar onSearch={handleSearch}/>
            <DogsTable rows={dogsTableData}/>
        </Container>
    );
};

export default Search;
