import React, { FC } from 'react';
import { Link } from 'react-router-dom';

const Admin: FC = () => {
    return (
        <div>
            <Link to="/admin/manage/create">
                {'Создайте '}
            </Link>
        </div>
    );
};


export default Admin;
