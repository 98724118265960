import React, { ComponentProps, FC, useContext, useEffect, useState } from 'react';
import { getDogById } from '../../../../service/api';
import PedigreeGraph from '../../../common/PedigreeGraph/PedigreeGraph';
import { DogContext } from '../../Details';
import TabPanel from '../TabPanel/TabPanel';
import { prepareDogTreeData } from './prepareDogTreeData';

type OverviewTabPanelProps = ComponentProps<typeof TabPanel>

const PedigreeTabPanel: FC<OverviewTabPanelProps> = (props) => {
    const dogNestedTreeData = useContext(DogContext)

    return (
        <TabPanel {...props}>
            <PedigreeGraph treeData={prepareDogTreeData(dogNestedTreeData)}/>
        </TabPanel>
    );
};

export default PedigreeTabPanel;
