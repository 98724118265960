import React, { FC, useEffect, useRef, useState } from 'react';
import { Binding, Diagram, GraphObject, Link, Margin, Picture, Shape, Size, TreeLayout, Node, TreeModel, TextBlock } from 'gojs';
import { TREE_DATA } from './TREE_DATA';
import { Box } from '@mui/material';
import { linkTemplate, nodeTemplate, treeLayoutConfig } from './treeTemplate';




// tree.addDiagramListener("ObjectSingleClicked", (e) => {
//     const part = e.subject.part;
//     if (!(part instanceof Link)) console.log("Clicked on " + part.data.key);
// });

const $ = GraphObject.make;

export interface PedigreeGraphProps {
    treeData: {
        parent: string | number;
    }[]
}
const PedigreeGraph: FC<PedigreeGraphProps> = ({ treeData }) => {
    const treeRef = useRef<HTMLDivElement>(null);
    const [treeConfig, setTreeConfig] = useState<any>(null);

    useEffect(() => {
        if (!treeRef.current) {
            return;
        }

        const tree = $(
            Diagram,
            treeRef.current,
            treeLayoutConfig,
        );
        tree.nodeTemplate = nodeTemplate;
        tree.linkTemplate = linkTemplate;

        setTreeConfig(tree);
    }, []);

    useEffect(() => {
        if (!treeConfig) {
            return
        }

        const model = $(TreeModel, {
            nodeDataArray: treeData,
        });

        debugger

        treeConfig.model = model;

    }, [treeData, treeConfig]);

    return (
        <Box ref={treeRef} sx={{ height: "600px"}} />
    );
};

export default PedigreeGraph;









