import { debug } from "console";
import { Dog } from "../../../../types/Dog";

export const prepareDogTreeData = (rootDog: any) => {
    const result: any = [];
    
    const flattenTreeData = ({id, name, mother, father, owner}: Dog, parentId = 1) => {
        const key = id + (parentId || 1)
        result.push({
            key,
            parent: parentId,
            name,
            owner,
        });

        if (mother) {
            flattenTreeData(mother, key);
        }
        if (father) {
            flattenTreeData(father, key);
        }
    };

    flattenTreeData(rootDog);

    return result;
}