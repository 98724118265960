import React, { FC, ReactElement } from 'react';
import { Grid } from '@mui/material';
import toArray from '../../../util/toArray';

const DescriptionsGrid: FC = ({ children}) => {


    return (
        <Grid container spacing={2} columns={12}>
            {
                toArray(children).map((child: ReactElement, index) => (
                    <Grid item sm={6} md={4} key={index}>
                        { React.cloneElement(child, {...child.props}) }
                    </Grid>
                ))
            }
        </Grid>
    );
};

export default DescriptionsGrid;
