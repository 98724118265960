import { Binding, Diagram, GraphObject, Link, Margin, Node, Picture, Shape, Size, TextBlock, TreeLayout } from "gojs";

const $ = GraphObject.make;

const subtractor = 2;
const node = {
    margin: 10 - subtractor,
    height: 51 - subtractor,
    width: 245 - (subtractor * 2),
    background: '#f8a800'
}


export const nodeTemplate = $(
    Node,
    { selectable: true },
    new Binding('height', 'height'),
    new Binding('width', 'width'),
    $(
        Shape,
        {
            desiredSize: new Size(node.width, node.height),
            figure: 'RoundedRectangle',
            fill: node.background,
            stroke: null,
            shadowVisible: true
        }
    ),
    $(
        Picture,
        {
            width: node.height,
            height: node.height,
            margin: new Margin(0.5, 0, 0, 0)
        },
        new Binding("source", "", (nodeData) => {
            if (nodeData.hasImage) {
                return '../assets/images/family/' + nodeData.key + '.png';
            }
            if (nodeData.gender.toUpperCase() == 'M') {
                return '../assets/images/family/male.png';
            }
            return '../assets/images/family/female.png';
        })
    ),
    $(
        Shape,
        {
            desiredSize: new Size(4, node.height),
            figure: "Rectangle",
            stroke: null,
            margin: new Margin(0, 0, 0, node.height - 1)
        },
        new Binding("fill", "",  (nodeData) => {
            return nodeData.gender.toUpperCase() == 'M' ? '#2799fd' : '#ea1a68';
        } )
    ),
    $(
        TextBlock,
        {
            font: "700 14px Google Sans, sans-serif",
            margin: new Margin(node.margin + 1, node.margin, 0, node.height + node.margin + 5),
            maxSize: new Size(node.width - node.height, 24)
        },
        new Binding("text", "", (nodeData: any) => {
            return nodeData.name.trim();

        })
    ),
    $(
        TextBlock,
        {
            font: "400 12px Roboto, sans-serif",
            margin: new Margin(24 + node.margin / 2, node.margin, node.margin, node.height + node.margin + 5),
            maxSize: new Size(node.width - node.height, 24)
        },
        new Binding("text", "", (nodeData) => {
            return nodeData.owner?.name;
        })
    ),
);


export const linkTemplate = $(
    Link,
    {
        selectable: false,
        routing: Link.Orthogonal,
        corner: 0
    },

    $(
        Shape,
        {
            strokeWidth: 1,
            stroke: "#6a6a6a"
        }
    )
);

export const treeLayoutConfig = {
    "undoManager.isEnabled": false,
    padding: node.height - 10,
    initialAutoScale: Diagram.Uniform,
    layout: $(
        TreeLayout, {
            angle: 0,
            layerSpacing: Math.max(node.margin * 6, 20),
            nodeSpacing: node.margin * 1.5,
        }
    )
}
