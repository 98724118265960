import React from 'react';

const genderOptions = [
    { text: 'Male', value: 'male' },
    { text: 'Female', value: 'female' },
    { text: 'Other', value: 'other' },
]

const RequestCreate = () => {
    return (<></>
        // <Segment>
        //     <Form>
        //         <Form.Group widths='equal'>
        //             <Form.Field
        //                 id='form-input-control-first-name'
        //                 control={Input}
        //                 label='First name'
        //                 placeholder='First name'
        //             />
        //             <Form.Field
        //                 id='form-input-control-last-name'
        //                 control={Input}
        //                 label='Last name'
        //                 placeholder='Last name'
        //             />
        //             <Form.Field
        //                 control={Select}
        //                 options={genderOptions}
        //                 label={{ children: 'Gender', htmlFor: 'form-select-control-gender' }}
        //                 placeholder='Gender'
        //                 search
        //                 searchInput={{ id: 'form-select-control-gender' }}
        //             />
        //         </Form.Group>
        //         <Form.Group widths='equal'>
        //             <Form.Field
        //                 id='form-input-control-first-name'
        //                 control={Input}
        //                 label='First name'
        //                 placeholder='First name'
        //             />
        //             <Form.Field
        //                 id='form-input-control-last-name'
        //                 control={Input}
        //                 label='Last name'
        //                 placeholder='Last name'
        //             />
        //             <Form.Field
        //                 control={Select}
        //                 options={genderOptions}
        //                 label={{ children: 'Gender', htmlFor: 'form-select-control-gender' }}
        //                 placeholder='Gender'
        //                 search
        //                 searchInput={{ id: 'form-select-control-gender' }}
        //             />
        //         </Form.Group>
        //         <Form.Field
        //             control={TextArea}
        //             label='Дополнительно'
        //             placeholder='Дополнительно'
        //         />
        //     </Form>
        // </Segment>
    );
};

export default RequestCreate;
