import axios from 'axios';
import { Dog } from '../types/Dog';
import { getFilterQueryParam } from '../util';

const API_ROOT ="https://dogs-db-by.onrender.com/api";


export const getDogById = async (id: string | number, withParents?: boolean) => {
    const { data } = await axios.get<Dog>(`${API_ROOT}/dogs/${id}`, {
        params: {
            withParents: Number(withParents) || undefined,
        }
    });

    return data;
};

export const searchDogsRegistry = async (filter?: Object) => {
    const { data } = await axios.get<Dog[]>(`${API_ROOT}/dogs`, {
        params: {
            ...getFilterQueryParam(filter)
        }
    });

    return data;
}
