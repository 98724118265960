import React, { ChangeEvent, ChangeEventHandler, FC, useState } from 'react';

import './DogsFilterBar.css';

import { options } from "./filterOprions";
import { Grid, MenuItem, Paper, TextField } from '@mui/material';
import { Button } from '@mui/material';

interface DogsFilterBarProps {
    onSearch: (filterParam: Partial<IFilterBarValue>) => void
}

export interface IFilterBarValue {
    name: string
    owner: string;
    location: string;
    registryId: string;
    type: string;
}

const DogsFilterBar: FC<DogsFilterBarProps> = ({onSearch}) => {
    const [filterBarValue, setFilterBarValue] = useState<Partial<IFilterBarValue>>({
        type: "male",
    })

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFilterBarValue(prevValue => {
            return {...prevValue, [event.target.name]: event.target.value}
        });
    }

    return (
        <Paper elevation={3} className={"filter-bar-wrapper"}>
            <Grid spacing={2}
                  columns={{ xs: 1, sm: 4, md: 8, lg: 12}}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  className="filter-bar0">
                <Grid item xs={2}>
                    <TextField fullWidth size={"small"} label='Name' name='name' placeholder='Name' onChange={handleChange}/>
                </Grid>
                <Grid item xs={2}>
                    <TextField fullWidth size={"small"} label='Владелец' name='owner.name' placeholder='Last name' onChange={handleChange}/>
                </Grid>
                <Grid item xs={2}>
                    <TextField fullWidth size={"small"} label='Регион' name='location' onChange={handleChange}/>
                </Grid>
                <Grid item xs={2}>
                    <TextField fullWidth size={"small"} label='Номер FCI/БКО' name = 'registryId' onChange={handleChange}/>
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        size={"small"}
                        fullWidth
                        select
                        label='Type'
                        name='type'
                        onChange={handleChange}
                        placeholder='Gender' value={filterBarValue?.type}
                    >
                        {options.map(el => (
                            <MenuItem key={el.value} value={el.value}>
                                {el.text}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item alignSelf={'flex-end'} xs={2} ml={"auto"}>
                    <div className={'filter-bar__actions'}>
                        <Button variant={"contained"} onClick={() => onSearch(filterBarValue)}>Найти</Button>
                    </div>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default DogsFilterBar;
