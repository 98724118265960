import React from 'react';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Box, Button, Menu, MenuItem, Toolbar, Typography } from '@mui/material';

interface Props {
 handleMenuItemClick: () => void
}

const MainMenu: FC<Props> = ({ handleMenuItemClick } ) => {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        News
                    </Typography>
                    <Button color="inherit">Login</Button>
                </Toolbar>
            </AppBar>
        </Box>
        // <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        //     <Link to="/search">
        //         <Typography onClick={handleMenuItemClick}>
        //             'Поиск'
        //         </Typography>
        //     </Link>
        //     <Link to="/search">
        //         <Typography onClick={handleMenuItemClick}>
        //             'Рейтинг'
        //         </Typography>
        //     </Link>
        //     <Link to="/search">
        //         <Typography onClick={handleMenuItemClick}>
        //             'Калькулятор'
        //         </Typography>
        //     </Link>
        //     <Link to="/admin">
        //         <Typography onClick={handleMenuItemClick}>
        //             'Управление'
        //         </Typography>
        //     </Link>
        // </Box>
    );
}


export default MainMenu;
