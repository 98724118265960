import React, { FC } from 'react';

import '../DataField/styles.scss';

export interface DataFieldProps {
    label: string,
    value: number | string | undefined,
}

const DataField: FC<DataFieldProps> = ({label, value}) => {
    return (
        <div className="data-field-item-container">
            <span className="data-field-item-label">{ `${label}: ` }</span>
            <span className="data-field-item-content">{ value }</span>
        </div>
    );
};

export default DataField;
