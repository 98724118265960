import { Card, CardActions, CardContent, CardMedia, Grid, Paper, Typography } from '@mui/material';
import React, { createContext, useEffect, useState } from 'react';
import OverviewCard from './OverviewCard/OverviewCard';
import DetailsPanel from './DetailsPanel/DetailsPanel';
import { useParams } from 'react-router-dom';
import { getDogById } from '../../service/api';
import { Dog } from '../../types/Dog';

export const DogContext = createContext<Dog>({} as Dog);

const Details = () => {

    const { dogId } = useParams();
    const [dogNestedTreeData, setDogNestedTreeData] = useState<Dog>({} as Dog)

    useEffect(() => {
        const fetchDogById = async (id: string) => {
            const response = await getDogById(Number(id), true);

            setDogNestedTreeData(response);
        }

        fetchDogById(dogId!);

    },[]);
    return (
        <DogContext.Provider value={dogNestedTreeData}>
            {dogNestedTreeData.id &&
            <Grid
                container
                spacing={2}
                columns={12}
                mt={"32px"}
                p={"8px"}
                justifyContent={"space-around"}>
                    <Grid item md={4} sm={9} xs={10}>
                        <OverviewCard />
                    </Grid>
                    <Grid item md={7}  sm={9} xs={10}>
                        <DetailsPanel/>
                    </Grid>
            </Grid>}
        </DogContext.Provider>
    );
};



export default Details;
