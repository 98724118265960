import React, { FC } from 'react';
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Dog } from '../../../types/Dog';

interface DogsTableProps {
    rows: Dog[];
}
const DogsTable: FC<DogsTableProps> = ({ rows }) => {
    const navigate = useNavigate();

    const handleTableItemClick = (id: number) => {
        navigate(`/details/${id}`, {});
    }

    return (
        <Paper elevation={3} sx={{ mt: "24px" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Dessert (100g serving)</TableCell>
                        <TableCell align="right">Calories</TableCell>
                        <TableCell align="right">Fat&nbsp;(g)</TableCell>
                        <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                        <TableCell align="right">Protein&nbsp;(g)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            onClick={() => handleTableItemClick(row.id)}
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">{row.name}</TableCell>
                            <TableCell align="right">{row.owner?.name}</TableCell>
                            <TableCell align="right">{row.birth_date}</TableCell>
                            <TableCell align="right">{row.location}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
};

export default DogsTable;
