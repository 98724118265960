import React from 'react';
import MainMenu from './AppBar/MainMenu';
import { Outlet } from 'react-router-dom';

const AppLayout = () => {
    return (
        <>
            <MainMenu handleMenuItemClick={() => {}}/>
            <Outlet/>
        </>

    );
};


export default AppLayout;
