import React from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';

import './App.css';

import Search from './Search/Search';
import RequestCreate from './RequestCreate/RequestCreate';
import Admin from './Admin/Admin';
import MainMenu from './AppBar/MainMenu';
import AppLayout from './AppLayout';
import { createTheme, ThemeProvider } from '@mui/material';
import { themeOptions } from '../theme/themeOptions';
import Details from './Details/Details';

const theme = createTheme(themeOptions);

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Routes>
                    <Route path="/" element={ <AppLayout/> }>
                        <Route path="/search" element={<Search />} />
                        <Route path="/admin/manage/create" element={ <RequestCreate/> }/>
                        <Route path="/admin" element={ <Admin/> } />
                        <Route path="/details/:dogId" element={ <Details />} />
                    </Route>
                </Routes>
            </Router>
        </ThemeProvider>
    )
}

export default App;
